
  import { defineComponent, onMounted, reactive, toRefs } from 'vue';
  import { useRouter } from 'vue-router';
  import { validatePhone, validatePassword } from '@/utils/validate';
  import { login, sendValidCode, forget, smsValidCode } from '@/api/header/login';
  import Cookies from 'js-cookie';
  import { ElMessage } from 'element-plus';
  import { getDomain } from '@/utils/urlHandler';

  export default defineComponent({
    setup() {
      const router = useRouter();
      const state = reactive({
        submitting: false,
        rememberPwd: false,
        pwdMode: true,
        sendFlag: false,
        forgotFlag: false, // false为登录，ture为忘记密码
        sendText: '获取验证码',
        loginForm: {
          phone: '',
          password: '',
        },
        codeLoginForm: {
          phone: '',
          smsValidCode: '',
        },
        resetForm: {
          phone: '',
          newPwd: '',
          smsCode: '',
        },
        loginFormRef: null,
        codeLoginFormRef: null,
        resetFormRef: null,
      });
      const loginRuls = Object.freeze({
        phone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      });
      const codeLoginRuls = Object.freeze({
        phone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
        smsValidCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      });
      const resetFormRule = Object.freeze({
        phone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
        newPwd: [{ required: true, validator: validatePassword, trigger: 'blur' }],
        smsCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      });
      const getSmsValidCode = async (phone: string, type: string) => {
        if (phone) {
          const res: any =
            type === 'login' ? await smsValidCode(phone) : await sendValidCode(phone);
          let n = 60;
          if (res.code === 0) {
            state.sendFlag = true;
            const timer = setInterval(() => {
              n -= 1;
              if (n < 0) {
                clearInterval(timer);
                state.sendText = '发送验证码';
                state.sendFlag = false;
                return;
              }
              state.sendText = `重新发送(${n}s)`;
            }, 1000);
          }
        } else {
          ElMessage({
            message: '请先填写手机号',
            type: 'error',
          });
        }
      };
      const resetFormHandle = () => {
        const resetRef: any = state.resetFormRef;
        resetRef.validate(async (valid: any) => {
          if (valid) {
            const res: any = await forget(state.resetForm);
            if (res.code === 0) {
              state.forgotFlag = false;
            }
          }
        });
      };

      const domain = getDomain(window.location.href);
      async function handleLogin() {
        try {
          state.submitting = true;
          const paramsAdd = { loginType: state.pwdMode ? 'pwd' : 'sms' };
          const form: any = state.pwdMode ? state.loginForm : state.codeLoginForm;
          if (state.rememberPwd) {
            localStorage.setItem('phone', form.phone);
            localStorage.setItem('password', form.password);
            localStorage.setItem('rememberFlag', 'true');
          } else {
            localStorage.setItem('phone', '');
            localStorage.setItem('password', '');
            localStorage.setItem('rememberFlag', '');
          }
          const params = { ...paramsAdd, ...form };

          const { data } = await login(params);
          sessionStorage.setItem('token', data);
          Cookies.set('token', data, { domain });
          Cookies.set('userName', data.userName, { domain });
          Cookies.set('tenantId', data?.tenantId, { domain });
          Cookies.set('tenantName', data?.tenantName, { domain });
          await router.push('/homepage');
        } catch (err) {
          console.log('登录失败', err);
        } finally {
          state.submitting = false;
        }
      }
      function handleLoginForm() {
        const formRef: any = state.loginFormRef;
        formRef.validate(async (valid: any) => {
          if (valid) {
            handleLogin();
          }
          return false;
        });
      }
      function handleCodeLoginForm() {
        const codeRef: any = state.codeLoginFormRef;
        codeRef.validate(async (valid: any) => {
          if (valid) {
            handleLogin();
          }
          return false;
        });
      }
      function submit(): void {
        if (state.pwdMode) {
          handleLoginForm();
        } else {
          handleCodeLoginForm();
        }
      }
      function changeLoginMode() {
        state.pwdMode = !state.pwdMode;
      }
      function toggleRemember(value: boolean) {
        state.rememberPwd = value;
      }
      onMounted(() => {
        const phone = localStorage.getItem('phone') as string;
        const password = localStorage.getItem('password') as string;
        state.rememberPwd = !!localStorage.getItem('rememberFlag');
        state.loginForm = {
          phone,
          password,
        };
      });
      return {
        ...toRefs(state),
        loginRuls,
        codeLoginRuls,
        getSmsValidCode,
        changeLoginMode,
        toggleRemember,
        submit,
        resetFormHandle,
        resetFormRule,
        brand: process.env.VUE_APP_BRAND_URL,
      };
    },
  });
