import $http from '@/utils/axios/axiosUtils';

export interface LoginParams {
  phone: string;
  password: string;
  smsValidCode: string;
}

export function login(params: LoginParams) {
  return $http({
    url: '/sso/pc/login',
    method: 'POST',
    data: params,
  });
}

export function smsValidCode(phone: string) {
  return $http({
    url: `/user/loginCode/${phone}`,
    method: 'get',
  });
}
// 发送验证码
export function sendValidCode(phone: string) {
  return $http({
    url: `/user/changeCode/${phone}`,
    method: 'get',
  });
}
// 忘记密码
export function forget(data: any) {
  return $http({
    url: '/user/pwd/forget',
    method: 'put',
    data,
  });
}
